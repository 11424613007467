<template>
  <input :placeholder="placeholder" :id="'tagifyinput' + id" />
</template>

<script>
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import {mapActions} from "vuex"
export default {
  name: "HelloWorld",
  props: {
    placeholder: String,
    options: Array,
    isSubmitted: Boolean,
    id: {
      default: "",
      type: String,
    },
    prevTags: {
      // default: [],
      type: Array,
    },
    removeFields:{
      type:Boolean,
      default:false
    },
    module:{
      default: () => (''),
      type: String
    }
  },
  data() {
    return {
      selectedUsers: [],
      ifSubmitted: false,
      tagify: null ,// Initialize tagify in data for better reactivity tracking,
      searchFunctionalityModule: ["folder", "topic", "inviteViaGlocal"]
    };
  },
  computed: {
    removeTagifyUser() {
      return this.isSubmitted;
    },
    prevTagsComplete(){
      // console.log("this.options",this.options);
      // console.log("this.prevTags",this.prevTags);
      const temp=[]
      this.options.map((option)=>{
        this.prevTags && this.prevTags.map((prevTag)=>{
          if (option.value===prevTag || option.label === prevTag) {
            const selectedContact = {label:option.label,value:option.value}
            if(option.key)
            selectedContact.key = option.key
            if(option.icon)
              selectedContact.icon = option.icon
            temp.push(selectedContact)
          }
        })
      })
      if (temp.length===0) this.tagify.loading(false)
      return temp
    }
  },
  methods: {
    ...mapActions({
      fetchUninvitedFolderUsers: 'folders/fetchUninvitedFolderUsers',
      fetchUninvitedTopicUsers: 'discover/fetchUninvitedTopicUsers',
      getUsersList: 'profile/getUsersList'
    }),
    tagTemplate(tagData) {
      // console.log("work");
      if(this.id === "addtopic") this.tagify.loading(false)
      let alreadyIncluded=false
      this.selectedUsers.map((user)=>{
        if (user===tagData.value){
          alreadyIncluded=true
        }
      })
      if (!alreadyIncluded) this.selectedUsers.push(tagData);
      this.$emit("fetchSelection", this.selectedUsers);
      // console.log("this.selectedUsers",this.selectedUsers);
      return `
        <tag title="${tagData.title || tagData.key}"
                contenteditable='false'
                spellcheck='false'
                tabIndex="-1""
                ${this.getAttributes(tagData)}
                class="tagify__tag ${tagData.class ? tagData.class : ""}">
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div class="tagify__tag__user">
                ${tagData.icon?
                  `<div class='tagify__tag__avatar-wrap ps-0'>
                    <img onerror="this.style.visibility='hidden'" class="rounded-circle w-25px me-2" src="${
                      tagData.icon
                    }">
                </div>`:''}
                <span class='tagify__tag-text'>${tagData.label}</span>
            </div>
        </tag>
    `;
    },

    suggestionItemTemplate(tagData) {
      // console.log("SUGGESTION");
      this.selectedUsers = this.selectedUsers.filter(
        (userid) => userid !== tagData.value
      );
      this.$emit("fetchSelection", this.selectedUsers);

      return `
        <div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item ${
              tagData.class ? tagData.class : ""
            }'
            tabindex="0"
            role="option">

            ${
              tagData.icon
                ? `
                    <div class='tagify__dropdown__item__avatar-wrap me-2'>
                        <img onerror="this.style.visibility='hidden'" class="rounded-circle w-50px me-2" src="${tagData.icon}">
                    </div>`
                : ""
            }

            <div class="tagify__dropdown__item__name">
                <p>${tagData.label}</p>
            </div>
        </div>
    `;
    },
    getAttributes(tagData) {
      const attributes = [];
      for (const [key, value] of Object.entries(tagData)) {
        if (key !== "value" && key !== "email" && key !== "class") {
          attributes.push(`${key}="${value}"`);
        }
      }
      return attributes.join(" ");
    },

    tagifyFunc(isSubmitted) {
      const whitelist = this.options; // initialize Tagify
      const input = document.getElementById(`tagifyinput${this.id}`);
      this.tagify = new Tagify(input, {
        id: this.id,
        delimiters: ",| ", // add new tags when a comma or a space character is entered
        tagTextProp: "name", // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
        enforceWhitelist: true,
        skipInvalid: true,
        isSubmitted: false, // do not remporarily add invalid tags
        dropdown: {
          closeOnSelect: false,
          enabled: 0,
          classname: "users-list",
          searchKeys: ["label", "key"], // very important to set by which keys to search for suggesttions when typing
        },
        templates: {
          tag: this.tagTemplate,
          dropdownItem: this.suggestionItemTemplate,
        },
        whitelist: whitelist,
        editTags: false,
      });
      if (this.id === "addtopic") this.tagify.loading(true);
      if (this.id === "findUsers") this.tagify.loading(true);

      this.tagify.on("remove", onRemoveTag.bind(this))
                  .on("input", this.searchUser.bind(this));

      function onRemoveTag(e) {
        console.log("REMOVE", e.detail.data.value);
        this.selectedUsers = this.selectedUsers.filter(
          (user) => user.value !== e.detail.data.value
        );
      this.$emit("fetchSelection", this.selectedUsers , e.detail.data.value);

        // console.log(this.selectedUsers);
        // this.allFetchedItems.map((el) => {
        //   if (el.label === e.detail.data.value) {
        //     this.selected = this.selected.filter((value) => value !== el.value);
        //   }
        // });
      }

      // this.tagify.on("dropdown:select", onSelectSuggestion);
      // this.tagify.on("blur", tagRemove);
      // let addAllSuggestionsElm;
      // let tagify = this.tagify;
      // function onDropdownShow(e) {
      //   var dropdownContentElm = e.detail.tagify.DOM.dropdown.content;
      //   if (tagify.suggestedListItems.length > 1) {
      //     addAllSuggestionsElm = getAddAllSuggestionsElm(tagify);

      //     // insert "addAllSuggestionsElm" as the first element in the suggestions list
      //     dropdownContentElm.insertBefore(
      //       addAllSuggestionsElm,
      //       dropdownContentElm.firstChild
      //     );
      //   }
      // }

      // function onSelectSuggestion(e) {
      //   if (e.detail.elm === addAllSuggestionsElm)
      //     tagify.dropdown.selectAll.call(this.tagify);
      // }

      // function getAddAllSuggestionsElm(tagify) {
      //   // suggestions items should be based on "dropdownItem" template

      //   return tagify.parseTemplate("dropdownItem", [
      //     {
      //       class: "addAll",
      //       label: "Add all",
      //       key:
      //         tagify.settings.whitelist.reduce(function(
      //           remainingSuggestions,
      //           item
      //         ) {
      //           return tagify.isTagDuplicate(item.value)
      //             ? remainingSuggestions
      //             : remainingSuggestions + 1;
      //         },
      //         0) + " Members",
      //     },
      //   ]);
      // }
      // function tagRemove(e) {
      //   if (isSubmitted) {
      //     this.tagify.removeAllTags(); // clear the input field after adding tags
      //   }
      // }
    },
    removeTags() {
      this.tagify.removeAllTags();
      this.selectedUsers = [];
      console.log("REMOVING ALL TAGS" )
    },
    searchUser(e){
        if (this.searchFunctionalityModule.includes(this.module)){
          const searchedName = e.detail.value
          if (searchedName){
            this.debounce(async ()=>{

              this.tagify.settings.whitelist.length = 0;
              this.tagify.loading(true).dropdown.hide.call(this.tagify)
              try{
                const users = await this.fetchUsers(searchedName)
                this.tagify.settings.whitelist.push(...users)
                this.tagify.loading(false).dropdown.show.call(this.tagify);

              }catch(err){
                this.tagify.loading(false)
                console.log(err, "Tagify contacts ERRRor")
              }

            }, 1500)
          }
        }
    },
    async searchFolderInvitedUsers(searchedName=''){

      const users = (await this.fetchUninvitedFolderUsers()).map(user=>({
          
          label: user.full_name,
          value: user.id,
          key: user.email,
          icon: user.profile_picture,
        
      }))

      return users

    },
    async searchTopicInvitedUsers(searchedName=''){
      const users = (await this.fetchUninvitedTopicUsers()).map(user=>({   
          label: user.full_name,
          value: user.id,
          key: user.email,
          icon: user.profile_picture,
        
      }))

      return users

    },
    async searchGlocalUsers(searchedName=''){
      const users = (await this.getUsersList({full_name: searchedName})).data.map(user=>({   
          label: user.profile.full_name,
          value: user.id,
          key: user.email,
          icon: user.profile.profile_picture,
        
      }))

      return users
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async fetchUsers(searchedName){
      if (this.module === 'folder')
        return  await this.searchFolderInvitedUsers(searchedName)
      else if (this.module === 'topic')
        return  await this.searchTopicInvitedUsers(searchedName)
      else if (this.module === 'inviteViaGlocal')
        return  await this.searchGlocalUsers(searchedName)
      else{
        return []
      }
    }
  },
  watch: {
    isSubmitted(val) {
      console.log("SUBITTED TAGIFY CONTACTS", val)
      this.ifSubmitted = val;
      if (val) this.removeTags();
    },
    options(val) {
      // console.log("Watch options", val);
      this.tagify.whitelist = val;
      this.tagify.addTags(this.prevTagsComplete)

    },
    removeFields(val){
      if (val) {
        this.tagify.removeAllTags()
      }

    }
  },
  mounted() {
    this.tagifyFunc(this.removeTagifyUser);

    // removing all the previous tags on this listener
    this.emitter.on('clear-tagify-contacts',()=>{
      this.tagify.removeAllTags();
      this.selectedUsers = []
      this.tagify.settings.whitelist.length = 0;
    })

    this.emitter.on('clear-tagify-contacts-tags',()=>{
      this.tagify.removeAllTags();
      this.selectedUsers = []
    })

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tagify {
  width: 100%;
  max-width: 700px;
  background: rgba(white, 0.8);
}

/* Suggestions items */
.tagify__dropdown.users-list {
  .tagify__dropdown__item {
    display: flex;
    align-items: center;
    .tagify__dropdown__item__avatar-wrap {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .tagify__dropdown__item__name {
      display: flex;
      align-items: center;
      p {
        font-family: $font-primary;
        font-size: 1.6rem;
        margin-bottom: 0;
        line-height: 2rem;
        margin-top: 0;
      }
    }
  }
}

.tagify__dropdown.users-list
  .tagify__dropdown__item:hover
  .tagify__dropdown__item__avatar-wrap {
  transform: scale(1.1);
}

.tagify__dropdown.users-list .tagify__dropdown__item__avatar-wrap {
  grid-area: avatar;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  background: #eee;
  transition: 0.1s ease-out;
}

.tagify__dropdown.users-list img {
  width: 100%;
  vertical-align: top;
}

.tagify__dropdown.users-list p {
  grid-area: name;
  width: 100%;
  align-self: center;
  font-family: $font-primary;
  font-size: 1.4rem;
  margin-bottom: 0;
  line-height: 2rem;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0.5rem;
}

.tagify__dropdown.users-list span {
  grid-area: email;
  width: 100%;
  font-size: 0.9em;
  opacity: 0.6;
}

.tagify__dropdown.users-list .tagify__dropdown__item__addAll {
  border-bottom: 1px solid #ddd;
  gap: 0;
}

/* Tags items */
.tagify__tag {
  white-space: nowrap;
}

.tagify__tag:hover .tagify__tag__avatar-wrap {
  transform: scale(1.6) translateX(-10%);
}

.tagify__tag .tagify__tag__avatar-wrap {
  width: 16px;
  height: 16px;
  white-space: normal;
  border-radius: 50%;
  background: silver;
  margin-right: 5px;
  transition: 0.12s ease-out;
}

.tagify__tag img {
  width: 100%;
  vertical-align: top;
  pointer-events: none;
}
.tagify__tag__user {
  display: flex;
  align-items: center;
  .tagify__tag__avatar-wrap {
    width: 1.8rem;
    height: 1.8rem;
    transform: inherit !important;
  }
  .tagify__tag-text {
    font-family: $font-primary;
    font-size: 1.2rem;
    margin-top: 0.1rem;
  }
}
</style>
